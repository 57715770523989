.content{
    background-color: black;
    height: 100vh;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
        width: 200px;
        height: 200px;
        object-fit: contain;
    }
    p{
        color: white;
        font-size: 2rem;
        padding: 0;
        margin: 0;  
        // font-weight: bold;
    }
}
.layout {
  display: flex;
  height: 100vh;
  overflow: none;
  p {
    padding: 0;
    margin: 0;
  }
  .leftSide {
    width: 45%;
    background-color: #000;
    overflow: none;
    div {
      height: 30%;
      padding-left: 20px;
      padding-top: 10px;
      img {
        width: 15%;
        height: 15%;
        object-fit: contain;
        margin: 20px 0px;
        margin-bottom: 2.3rem;
      }
      p {
        color: var(--greyColor);
        font-size: 1rem;
        margin-bottom: 1rem;
        width: 80%;
      }
      h3 {
        color: #fff;
        font-size: 1.5rem;
        margin-bottom: 1rem;
        margin-bottom: 2.5rem;
        span {
          color: var(--secondaryColor);
        }
      }
    }
    .authImg {
      width: 100%;
      height: 55%;
      text-align: center;
      object-fit: contain;
      margin-top: 2rem;
    }
  }
  .rightSide {
    width: 65%;
    background-color: #fff;
    overflow: none;
    .outlet{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 95%;
      width: 100%;
    }
    .lang {
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;
    }
  }
}

@media (max-width: 1200px) {
  .layout {
    .leftSide {
      display: none;
      
    }
    .rightSide {
      width: 100%;
    }
  }
}
@media(max-width : 1200px){
  .layout {
    .leftSide {
      .authImg{
        margin-top: 8rem !important;
      }
    }
  }
}
@media(max-width : 1600px){
  .layout {
    .leftSide {
      .authImg{
        margin-top: 6rem !important;
      }
    }
  }
}
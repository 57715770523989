.mainLayout {
    display: flex;
    height: 95vh;
    gap: 20px;
    background-color: var(--greyColor);
    padding: 20px;
    .sideMenu{
        width: 15%;
    }
    .rightSide{
        width: 85%;
        .main{
            background-color: #fff;
            margin-top: 20px;
            border-radius: 10px;
            height: 97%;
            overflow: auto;
        }
    }
}

@media (max-width: 1024px) {
    .mainLayout {
      .sideMenu {
        display: none;
      }
      .rightSide {
        width: 100%;
      }
    }
  }
  
.header{
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    border-radius: 10px;
    height: 60px;
    .pageTitle{
        font-size: 20px;
        font-weight: bold;
        display: flex;
        align-items: center;

        button {
            display: none;
           
          
        }
    }
    .info{
        display: flex;
        // gap: 10px;
        justify-content: center;
        align-items: center;
    }
}
@media screen and (max-width:1024px) {
    .header {
        .pageTitle{
       

            button {
                display: block;
                margin-right: 1rem;
                margin-bottom: 5px;
                background-color: transparent;
                border: none;
              
            }
        }
    }
   
}
.toast {
  .subBox {
    padding: 10px 30px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .words {
      display: flex;
      gap: 10px;
      h4 {
        color: var(--errorColor300);
      }
      p {
        color: var(--primaryColor700);
      }
      svg {
        color: var(--errorColor300);
        font-size: 1.5rem;
        font-weight: 900;
      }
    }
    .close {
      svg {
        color: var(--grayColor400);
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  opacity: 0.9;
  position: fixed;
  z-index: 9999;
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: 5px;
  width: 38%;
  border-radius: 10px;
  background-image: url("../../assets/images/error-toast.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
}

@media screen and (max-width: 1000px) {
  .toast {
    width: 60%;
    .subBox {
        padding: 10px;
        margin: 10px 0px;
      .words {
        display: flex;
        gap: 10px;
        h4 {
          color: var(--errorColor300);
          font-size: 20px;
        }
        p {
          color: var(--primaryColor700);
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .toast {
    width: 90%;
  }
}

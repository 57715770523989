@import url('https://fonts.googleapis.com/css2?family=Cherry+Bomb+One&family=DM+Sans:opsz,wght@9..40,400;9..40,700&family=Inter:wght@400;700&family=Pacifico&family=Buenard&family=Playfair+Display&family=Poppins:wght@400;700&family=Roboto:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F0F0F0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  p, span, h1, h2, h3, h4, h5, h6, a, label, button{
    font-family: "Poppins";
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F0F0F0 !important;
}
.perc {
  position: absolute;
  top: 50%;
  left: 46px;
  transform: translateY(-50%);
  color: #686868 !important;
}
:root {
  --primaryColor: #000;
  --secondaryColor: #FEEE00;
  --darkYellow: #FEDB16;
  --lightColor: #FFF9E8;
  --yellowColor: #FFFDDD;
  --whiteColor: #fff;
  --greyColor: #F0F0F0;
  --errorColor: #FF0000;
  --errorColor700: #FF3232;
  --greyColor800: #828282;
  --greyColor700: #545454;
  --greyColor600: #EFEDED;
  --greyColor400:#d9d9d9;
  --greyColor200: #949DB2;
  --greyColor500: #DDDDDD;
  --greyColor300: #ECF0F2;
  --greyColor100: #DBDBDB;
  --darkGreyColor: #666C7E;
  --successColor: #008000;
  --greenColor: #E7FFE5;
  --redColor: #FFECEC;
  --blueDarkColor: #4E4E65;
  --lightBlueColor: #EAF5FF;
  --blueColor: #4EB5FF;
}


/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}

.hNBumD{
  overflow-x: hidden !important;
}

.bnYPPS{
  overflow-x: hidden !important;
}

.ctgXpK{
  overflow-x: hidden !important;
}

.fWlzdU{
  overflow-y: auto !important;
  max-height: 60vh !important;
}

.celVQH{
  overflow-y: auto !important;
  max-height: 60vh !important;
}

.modal-header{
  border-bottom: 0 !important;
  margin: 0 !important;
}

.modal-content {
  border-radius: 25px !important;
}
.modal-body{
  padding: 0rem 3rem !important;
  padding-bottom: 3rem !important;
}